import React from "react"
import { navigate } from "gatsby"
import BookingDetails from "./bookingDetails"

function BookingList({userBookings,fetchUserData}) {

  return <li className="uk-active">
    {(userBookings && userBookings.length) ?
      <>
        <div className="uk-margin">
          <div className="uk-margin">Ongoing Bookings</div>
          {userBookings.filter(booking=>booking.bookingIdLong!==null)
            .filter(booking => booking.status === "BOOKED")
            .sort((a,b)=>{
              return a.bookingId<b.bookingId?1:-1;
            })
            .map(booking => {
              return <BookingDetails {...{booking,userBookings,fetchUserData}}/>
            })}
        </div>
        <div className="uk-margin">
          <div className="uk-margin">Recent Bookings</div>
          <div className="uk-overflow-auto">
            <table
              className="uk-table uk-table-small uk-table-divider uk-table-hover"
              style={{ backgroundColor: "#F6F6F6" }}
            >
              {userBookings
              .filter(booking=>booking.bookingIdLong!==null)
                .filter(booking => booking.status !== "BOOKED")
                .map(booking => (
                  <tr>
                    <td>Booking ID: {booking.bookingIdLong}</td>
                    <td>
                      Paid Amount:{" "}
                      {
                        booking.servicesAndPackages[0]["booking"][
                          "final_price"
                          ]
                      }
                    </td>
                    <td>Date:{booking["date"]}</td>
                    <td>
                                  <span
                                    className="uk-margin-small-right"
                                    uk-icon={booking.status==="CANCELLED"?"close":"check"}
                                  />
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      </> :
      <p>Oops no booking to show here</p>
    }
  </li>

}

export default BookingList