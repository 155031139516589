import React, { useContext, useEffect, useRef, useState } from "react"
import { userContext } from "../context/userContext/userContext"
import { base, URLS } from "../config/urls"
import axios from "axios"
import { navigate } from "gatsby"
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import ClientInfo from "../components/dashboard/clientInfo"
import DashboardNavigator from "../components/dashboard/dashboardNavigator"
import BookingList from "../components/dashboard/bookings/bookingList"
import ProfileSetting from "../components/dashboard/profileSetting"
import SubscriptionList from "../components/dashboard/subscription/subscriptionList"
import { showConfirmation, showError, showMobilePrompt, showPrompt, showSuccess } from "../uitlity/Utility"
import moment from "moment"
import Loader from "../components/loader/Loader"
import Footer from "../components/footer/footer"

const Dashboard = () => {
  const userCtx = useContext(userContext)
  const [oldPassword, setOldPassword] = useState()
  const [newPassword, setNewPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()

  const [oneCapitalError, setOneCapitalError] = useState(false)
  const [oneLowerError, setOneLowerError] = useState(false)
  const [atLeastOneNumberError, setAtLeastOneNumberError] = useState(false)
  const [specialCharacterError, setSpecialCharacterError] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [userData, setUserData] = useState(null)
  const [userBookings, setUserBookings] = useState([])
  const [userSubscriptions, setUserSubscriptions] = useState([])
  const [newUserData, setNewUserData] = useState()
  const [showMobile, setShowMobile] = useState(null)

  const submitPassword = () => {
    // debugger
    if (/[A-Z]/.test(newPassword) &&
      /[a-z]/.test(newPassword) &&
      /[0-9]/.test(newPassword) &&
      /\W/.test(newPassword) &&
      newPassword === confirmPassword
    ) {
      axios({
        method: "put",
        url: URLS.updatePasswordUrl(),
        headers: {
          Authorization: "Token " + userCtx.token
        },
        data: {
          old_password: oldPassword,
          new_password: newPassword,
          confirm_password: confirmPassword
        }
      })
        .then(result => {
          setUserData(result.data)
          userCtx.updateUserData()
          showSuccess("Password Updated")

        })
        .catch(e => showError("Error setting password"))
    }
  }
  const fetchUserData = () => {
    if (userCtx.token) {
      axios
        .all([
          axios.get(URLS.userDetailUrl(), {
            headers: {
              Authorization: "Token " + userCtx.token
            }
          }),
          axios.get(URLS.subscriptionUrl(), {
            headers: {
              Authorization: "Token " + userCtx.token
            }
          })
        ])
        .then(([{ data: userDetails }, { data: subscriptionsDetails }]) => {
          let bookings = userDetails["customers_for_booking"]
          bookings = bookings.map(bookingDetails => {
            return bookingDetails["salon_bookings"].map(salonBooking => {
              let services = salonBooking["services_and_packages"]
              let startTime = services.reduce((prev, current) => {
                let time = moment(current.start_time, "HH:mm:ss")
                if (prev) {
                  if (time.isBefore(prev))
                    return time
                  return prev
                }
                return time
              }, null)
              return {
                customerName: bookingDetails.name,
                customerEmail: bookingDetails.email,
                customerPhone: bookingDetails.phone,
                bookingId: salonBooking.id,
                bookingIdLong: salonBooking.booking_id,
                date: salonBooking.booking_date,
                status: salonBooking.status,
                subscriptionId: salonBooking.subscription_id,
                servicesAndPackages: salonBooking.services_and_packages,
                salonId: salonBooking.salon.id,
                salonName: salonBooking.salon.name,
                paid: salonBooking.paid,
                startTime: salonBooking.booking_date + " " + startTime.format("HH:mm")
              }
            })
          })
          bookings = bookings.reduce((prev, customerBookings) => {
            return prev.concat(customerBookings)
          }, [])
          let oldData = JSON.parse(JSON.stringify(userDetails))
          let updatedData = JSON.parse(JSON.stringify(userDetails))

          for (let subscriptionsDetail of subscriptionsDetails) {
            for (let booking of subscriptionsDetail.bookings) {
              booking.month = booking.month % 13
            }

          }
          setUserBookings(bookings)
          setUserData(oldData)

          setUserSubscriptions(subscriptionsDetails)
          setNewUserData(updatedData)
        })
        .catch(error => console.log(error))
    } else {
      navigate("/login", { replace: true })
    }
  }
  useEffect(() => {
    // fetchUserData();
    let userDetails = {
      customerName: "Name",
      customerEmail: "Email",
      customerPhone: "Phone",
      bookingId: 1,
      bookingIdLong: 1,
      date: new Date(),
      status: "Booked",
      subscriptionId: 1,
      servicesAndPackages: [],
      salonId: 1,
      salonName: "Salon Name",
      paid: "Paid",
      startTime: new Date()
    }
    const bookings = []
    let oldData = userDetails
    let updatedData = userDetails


    setUserBookings(bookings)
    setUserData(oldData)
    setUserSubscriptions(null)
    setNewUserData(updatedData)
  }, [])

  const updateMobileNumber = (mobile) => {
    axios.patch(URLS.editProfile(userCtx.user.id),
      { phone_number: mobile },
      {
        headers: {
          Authorization: "Token " + userCtx.token
        }
      }
    ).then(resp => {
      userCtx.setUser(prev => {
        prev.phone_number = mobile
        return { ...prev }
      })
      fetchUserData()
      userCtx.updateUserData()
    }).catch(e => {
    })
  }
  const Logout = () => {
    axios({
      method: "post",
      url: URLS.logoutUrl(),
      headers: {
        Authorization: "Token " + userCtx.token
      }
    })
      .then(result => {
        navigate("/login", { replace: true })
      })
      .catch(error => console.log(error))
    userCtx.setUser(null)
  }
  useEffect(() => {

    if (userCtx.user && (!userCtx.user.phone_number || !userCtx.user.phone_number.length)) {
      showMobilePrompt("Mobile:").then(mobile => {
          if (/^[\d]{10}$/.test(mobile)) {
            updateMobileNumber(mobile)
          } else if (mobile === null) {
            showError("Mobile Number Required For LoggedIn User")
            showConfirmation("Logout", () => {
              Logout()
            }, () => {
              setShowMobile({})
            })
          } else {
            showError("Invalid Mobile Number")
            setShowMobile({})
          }
        }
      ).catch(e => {
        console.log(e)
      })
    }
  }, [showMobile, userCtx.user])


  const Submit = event => {
    event.preventDefault()
    let first_name = event.target[0].value
    let last_name = event.target[1].value
    let username = event.target[2].value
    let gender = event.target[3].checked ? "MALE" : "FEMALE"
    let phone_number = event.target[4].value
    let email = event.target[5].value
    let facebook_profile = event.target[6].value
    let twitter_profile = event.target[7].value
    let linkedin_profile = event.target[8].value
    let youtube_profile = event.target[9].value

    axios({
      method: "put",
      url: URLS.editProfile(userData.id),
      data: {
        first_name: first_name,
        last_name: last_name,
        username: username,
        gender: gender,
        phone_number: phone_number,
        email: email,
        facebook_profile: facebook_profile,
        twitter_profile: twitter_profile,
        linkedin_profile: linkedin_profile,
        youtube_profile: youtube_profile
      },
      headers: {
        Authorization: "Token " + userCtx.token
      }
    })
      .then(response => {
        fetchUserData()
        userCtx.updateUserData()
        showSuccess("Details Updated")
      })
      .catch(error => {
        showError("Please Enter Valid Data")
      })

    return false
  }

  const handleChange = e => {
    let userdata = { ...newUserData }
    userdata[e.target.name] = e.target.name === "gender"
      ? e.target.checked
        ? "MALE"
        : "FEMALE"
      : e.target.value

    setNewUserData(userdata)
  }

  return userData!=null? (
    <Layout>
      <SEO title="Home"/>
      <ClientInfo {...{ userData, fetchUserData }}/>
      <div className="uk-section">
        <div className="uk-container uk-container-medium">
          <div>
            <div className="flex">
              <DashboardNavigator/>
              <div className="w-70">
                <ul id="component-nav" className="uk-switcher">
                  <BookingList {...{ userBookings, fetchUserData }}/>
                  <ProfileSetting {...{
                    newUserData,
                    Submit,
                    handleChange,
                    oldPassword,
                    newPassword,
                    setOldPassword,
                    setOneCapitalError,
                    setOneLowerError,
                    setAtLeastOneNumberError,
                    setSpecialCharacterError,
                    setNewPassword,
                    confirmPassword,
                    setPasswordMatch,
                    setConfirmPassword,
                    oneCapitalError,
                    oneLowerError,
                    atLeastOneNumberError,
                    specialCharacterError,
                    passwordMatch,
                    submitPassword
                  }}/>
                  <SubscriptionList {...{
                    userSubscriptions
                  }}/>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  ) : (
    <Loader/>
  )
}

export default Dashboard
