import React from "react"

function DashboardNavigator() {
  return <div className="w-30 pr4-ns">
    <div className="uk-card uk-card-default pa4-ns pa2">
      <ul
        className="uk-nav uk-nav-default"
        data-uk-switcher="connect: #component-nav; animation: uk-animation-fade"
      >
        <li className="uk-active">
          <a href="#">
            <div className="ongoing-booking">
              <div>Ongoing Booking</div>
            </div>
          </a>
        </li>
        <li>
          <a href="#">Profile Settings</a>
        </li>
        <li>
          <a href="#">Subscription Details</a>
        </li>
      </ul>
    </div>
  </div>

}

export default DashboardNavigator