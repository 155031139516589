import React, { useContext } from "react"
import boyImg from "../../images/icons/boy.svg"
import Layout from "../layout/layout"
import axios from "axios"
import { base, URLS } from "../../config/urls"
import { navigate } from "gatsby"
import { userContext } from "../../context/userContext/userContext"
import { showError } from "../../uitlity/Utility"

function ClientInfo({userData,fetchUserData}) {
  const userCtx = useContext(userContext)
  const imageHandler = e => {
    const form = new FormData()
    form.append(e.target.name, e.target.files[0])
    axios({
      method: "put",
      url: URLS.editProfile(userData.id),
      data: form,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Token " + userCtx.token
      }
    })
      .then(response => {
        fetchUserData()
        userCtx.updateUserData()
      })
      .catch(error => {
        showError("Please select valid file")
      })
  }
  const Logout = () => {
    axios({
      method: "post",
      url: URLS.logoutUrl(),
      headers: {
        Authorization: "Token " + userCtx.token
      }
    })
      .then(result => {
        navigate("/login", { replace: true })
      })
      .catch(error => console.log(error))
    userCtx.setUser(null)
  }
  return <div className="uk-section">
    <div className="uk-container uk-container-medium">
      <div className="uk-card uk-card-default uk-card-body">
        <div className="flex items-center justify-between ">
          <div className="w-100 w-40-ns">
            <table className="uk-table uk-table-small">
              <tr>
                <td>Phone No.</td>
                <td>+91 {userData.phone_number}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>{userData.email}</td>
              </tr>
              <tr>
                <td>Full Name</td>
                <td>{userData.first_name}</td>
              </tr>
              <tr>
                <td>Gender</td>
                <td>{userData.gender}</td>
              </tr>
            </table>
          </div>
          <div className="w-100 w-40-ns uk-text-center">
            <img
              className="uk-border-circle uk-box-shadow-medium"
              src={
                (userData && userData["profile_picture"] !== "undefined")
                  ? userData["profile_picture"]
                  : boyImg
              }
              style={{ border: "10px solid #fff" }}
              width="200"
              height="200"
              alt="user profile"
            />
            <br/> <br/>
            <button
              className="uk-button uk-button-small uk-button-secondary"
              onClick={() => {
                document.getElementById("file-input").click()
              }}
            >
              Upload Photo
            </button>
            <input
              style={{
                display: "none"
              }}
              id={"file-input"}
              type="file"
              name="profile_picture"
              // value={updatedData.profilePicture}
              onChange={imageHandler}
            />
            <br/>
            <br/>
            <button className="uk-button" onClick={Logout}>
              Logout
            </button>
          </div>
          <div className="w-100 w-40-ns">
            <table className="uk-table uk-table-small">

              <tr>
                <td>Facebook</td>
                <td>{userData.facebook_profile}</td>
              </tr>
              <tr>
                <td>Twitter</td>
                <td>{userData.twitter_profile}</td>
              </tr>
              <tr>
                <td>Instagram</td>
                <td>{userData.linkedin_profile}</td>
              </tr>
              {/* <tr>
                <td>Youtube</td>
                <td>{userData.youtube_profile}</td>
              </tr> */}
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default ClientInfo
