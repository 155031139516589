import React from "react"
import { navigate } from "gatsby"
import SubscriptionDetails from "./subscriptionDetails"

function SubscriptionList(
  // {
  //                           userSubscriptions,
  //                         }
                          ) {
  return <li>
    {/*{userSubscriptions.length ? userSubscriptions.map(subscription => {// FINDING OUT THE NEXT BOOKING DATE*/}
    {/*  let nextExpectedBooking = {month : -1};*/}
    {/*  let i = 0*/}
    {/*  while (subscription.bookings[i]) {*/}
    {/*    const booking = subscription.bookings[i++]*/}
    {/*    if (booking.month >= nextExpectedBooking.month && booking.status === 1) {*/}
    {/*      nextExpectedBooking = booking*/}
    {/*      break*/}
    {/*    }*/}
    {/*  }*/}
    {/*  return <SubscriptionDetails {...{*/}
    {/*    subscription,*/}
    {/*    nextExpectedBooking,*/}
    {/*  }} />*/}
    {/*}) :*/}
      <p>Oops, No subscriptions</p>
    {/*}*/}
      </li>

      }

      export default SubscriptionList
