import React from "react"

function ProfileSetting({
                          newUserData,
                          Submit,
                          handleChange,
                          oldPassword,
                          newPassword,
                          setOldPassword,
                          setOneCapitalError,
                          setOneLowerError,
                          setAtLeastOneNumberError,
                          setSpecialCharacterError,
                          setNewPassword,
                          confirmPassword,
                          setPasswordMatch,
                          setConfirmPassword,
                          oneCapitalError,
                          oneLowerError,
                          atLeastOneNumberError,
                          specialCharacterError,
                          passwordMatch,
                          submitPassword,
                        }) {
  return <li>
    {newUserData && (
      <form onSubmit={Submit} className="uk-grid-small uk-child-width-1-3@s uk-child-width-1-1" data-uk-grid>
        {/* <div className="uk-margin">
                        <label>
                          Full Name:
                          <input
                            className="uk-input"
                            name={"first_name"}
                            placeholder="Your Full Name"
                          />
                        </label>
                      </div> */}
        <div>
        <div className="uk-margin">
          <label>
            First Name:
            <input
              className="uk-input"
              name={"first_name"}
              placeholder="Your first Name"
              value={newUserData.first_name}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>
        <div>
        <div className="uk-margin">
          <label>
            Last Name:
            <input
              className="uk-input"
              name={"last_name"}
              placeholder="Your last Name"
              value={newUserData.last_name}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>
        <div>
        <div className="uk-margin">
          <label>
            Username:
            <input
              className="uk-input"
              name={"username"}
              placeholder="Your username"
              value={newUserData.username}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>

        <div>
        <div className="uk-margin">
          <label>
            Gender
            <input
              className="uk-checkbox"
              name={"gender"}
              key="gender"
              type="checkbox"
              checked={newUserData.gender === "MALE"}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>
        <div>
        <div className="uk-margin">
          <label>
            Phone Number
            <input
              className="uk-input"
              name={"phone_number"}
              placeholder="Your Number"
              value={newUserData.phone_number}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>
        <div>
        <div className="uk-margin">
          <label>
            Email
            <input
              className="uk-input"
              name={"email"}
              placeholder="Your Email"
              value={newUserData.email}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>

        <div>
        <div className="uk-margin">
          <label>
            Facebook Profile
            <input
              className="uk-input"
              name={"facebook_profile"}
              placeholder="Your Facebook Profile"
              value={newUserData.facebook_profile}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>
        <div>
        <div className="uk-margin">
          <label>
            Twitter Profile
            <input
              className="uk-input"
              name={"twitter_profile"}
              placeholder="Your Twitter Profile"
              value={newUserData.twitter_profile}
              onChange={handleChange}
            />
          </label>
        </div>
        </div>
        <div className="uk-margin">
          <label>
            Instagram Profile
            <input
              className="uk-input"
              name={"linkedin_profile"}
              placeholder="Your Linkedin Profile"
              value={newUserData.linkedin_profile}
              onChange={handleChange}
            />
          </label>
        </div>
        <button
          type="submit"
          className="uk-button uk-button-primary"
        >
          Save Details
        </button>
      </form>
    )}
    <div className="uk-grid-small" data-uk-grid>
            <div className="uk-width-a-a">
              <div className="uk-margin">
                <label>
                  Old Password
                  <input
                    className="uk-input"
                    value={oldPassword}
                    placeholder="Your Existing Password"
                    onChange={e => setOldPassword(e.target.value)}
                  />
                </label>
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1">
            <div className="uk-margin">
                <label>
                  New Password
                  <input
                    className="uk-input"
                    value={newPassword}
                    placeholder="Create a New Password"
                    onChange={e => {
                      let data = e.target.value
                      setOneCapitalError(!/[A-Z]/.test(data))
                      setOneLowerError(!/[a-z]/.test(data))
                      setAtLeastOneNumberError(!/[0-9]/.test(data))
                      setSpecialCharacterError(!/\W/.test(data))
                      setNewPassword(data)
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="uk-width-1-2@s uk-width-1-1">
              <div className="uk-margin">
                <label>
                  Confirm Password
                  <input
                    className="uk-input"
                    value={confirmPassword}
                    placeholder="confirm password"
                    onChange={e => {
                      const data = e.target.value
                      setPasswordMatch(newPassword === data)
                      setConfirmPassword(data)
                    }}
                  />
                </label>
              </div>
            </div>
            <div className="uk-width-1-1">
                <div className="uk-margin" style={{ color: "red" }}>
                  {oneCapitalError && <div>one capital letter</div>}
                  {oneLowerError && <div>one lower letter</div>}
                  {atLeastOneNumberError && (
                    <div> at least one number</div>
                  )}
                  {specialCharacterError && (
                    <div> at least one special character</div>
                  )}
                  {!passwordMatch && <div>password is not match</div>}
                </div>
            </div>
    </div>
    
    <div className="uk-margin">
      <button
        className="uk-button uk-button-primary"
        onClick={submitPassword}
      >
        Save password
      </button>
    </div>
    
    
  </li>

}

export default ProfileSetting