import React, { useContext } from "react"
import { navigate } from "gatsby"
import { razorpayContext } from "../../../context/razorpayContext/razorpayContext"
import axios from "axios"
import { base, URLS } from "../../../config/urls"
import { showError } from "../../../uitlity/Utility"
import moment from "moment"
import { userContext } from "../../../context/userContext/userContext"

const BookingDetails = ({
                          booking,
                          fetchUserData
                        }) => {
  const razorCtx = useContext(razorpayContext)
  const userCtx = useContext(userContext)
  const currentTime = moment()
  return (
    <div
      className="ongoing-booking-card uk-card pa4-ns pa2"
      style={{ backgroundColor: "#F6F6F6" }}
    >
      <div className="flex flex-wrap justify-between">
        <div className="uk-text-small uk-text-muted">
          Booking ID : {booking.bookingIdLong}
        </div>
        <div className="uk-text-small uk-text-muted ml3">
          Date: {booking.date}
        </div>
      </div>
      <div className="uk-text-large uk-text-primary pv2">
        {booking.salonName}
      </div>
      <div
        className="uk-grid uk-child-width-1-1 uk-child-width-1-3@m uk-child-width-1-2@s uk-grid-divider"
        data-uk-grid
      >
        <div>
          <table className="uk-table uk-table-small">
            {booking.servicesAndPackages.map(
              serviceOrPackage => {
                return (
                  <tr>
                    <td>
                      {serviceOrPackage["is_package"]
                        ? serviceOrPackage["package"]
                          .name
                        : serviceOrPackage.service.name}
                    </td>
                    <td>
                      {serviceOrPackage["is_package"]
                        ? serviceOrPackage["package"]
                          .price
                        : serviceOrPackage.service
                          .price}
                    </td>
                  </tr>
                )
              }
            )}
          </table>
        </div>
        <div>
          <table className="uk-table uk-table-small">
            <tr>
              <td>Subtotal</td>
              <td>
                {
                  booking.servicesAndPackages[0]["booking"]["calculated_price"]
                }
              </td>
            </tr>
            <tr>
              <td>Discount</td>
              <td>
                {
                  booking.servicesAndPackages[0][
                    "booking"
                    ]["discount"]
                }
              </td>
            </tr>
            <tr>
              <td>After Discount</td>
              <td>
                {
                  booking.servicesAndPackages[0][
                    "booking"
                    ]["final_price"]
                }
              </td>
            </tr>
            {/*<tr>*/}
            {/*<td>*/}
            {/*  Taxes :*/}
            {/*</td>*/}
            {/*<td>*/}
            {/*  Total*/}
            {/*</td>*/}
            {/*</tr>*/}
          </table>
        </div>
        <div className="pa2">
          {(booking.subscriptionId === null) &&
          <button
            className="uk-button uk-button-primary w-100"
            onClick={() => {
              axios.post(URLS.cancelBooking(), {
                "booking_id": booking.bookingId
              }, {
                headers: {
                  Authorization: "Token " + userCtx.token
                }
              })
                .then(({ data: resp }) => {
                  fetchUserData()
                  userCtx.updateUserData()
                }).catch(e => {
                showError(e.response.data.msg)
              })
            }}
          >
            Cancel
          </button>}
          {(function() {
            let currentTime = moment()
            return (moment(booking.startTime,"YYYY-MM-DD HH:mm").diff(currentTime, "minutes") > 90)
          })() &&
          <button
            className="uk-button uk-button-primary w-100"
            onClick={() => {
              // debugger
              navigate("/bookingReschedule", {
                state: {
                  bookingData : booking,
                }
              })
            }}
          >
            Reschedule
          </button>}
          {/*<div style={{ height: "25px" }}/>*/}
          {!booking.paid ? (
            <button className="uk-button uk-button-secondary w-100"
                    onClick={() => {
                      razorCtx.displayRazorpay({
                        bookingId: booking.bookingId,
                        first_name: booking.customerName,
                        phone_number: booking.customerPhone,
                        email: booking.customerEmail
                      }, fetchUserData, (e) => {
                        console.log(e)
                      })
                    }}
                    disabled={!razorCtx.available}
            >
              Pay
            </button>
          ) : "Paid"}
        </div>
      </div>
    </div>
  )
}

export default BookingDetails
